<template>
  <div v-if="($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') && $store.state.fastPermissoes.ativo == 'S' && $store.state.fastPlataformaModulos.modulo_secretaria">
    <div class="fd-app-wrap">
      <!--#region Breadcrumb -->
      <div class="container">
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a href="/minhas-plataformas" class="aluno_font_color" @click.prevent="$router.push('/minhas-plataformas')">Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)">Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')">Modo tesouraria</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a class="aluno_font_color" href="#">Título da página teste</a>
            </li>
          </ul>
        </nav>
      </div>
      <!--#endregion -->
      <!--#region Conteúdo principal -->
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Título da página <span>teste</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>              
              <div class="row mt-4">
                <!-- Conteúdo aqui -->

              </div>
              <div class="mt-5 mb-5">
                <a class="btn btn-red-hollow" :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'" @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')">
                  <b-icon-arrow-return-left /> Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--#endregion -->
    </div>
    <!--#region Modals -->
    <b-modal id="modalExemplo" size="xl" scrollable title="Modal exemplo" >
      <div></div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Fechar <b-icon-x-circle-fill />
        </b-button>
      </template>
    </b-modal>
    <!--#endregion -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
export default {
  name: "HomeInternoTesourariaPosicaoContasReceber",
  components: { },
  mixins: [methods],
  data: function() {
    return {
      urlFastEAD: settings.endFastEad,
      //#region Variáveis 1 da página teste
      var1: "",
      //#endregion
      //#region Variáveis 2 da página teste
      var2: ""
      //#endregion
    };
  },
  mounted: function() {
    this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
    .then(() => {
      this.getDadosMetodo1()
      this.$store.state.fastCarregando = false
    })
    .catch((e) => {
      this.exibeToasty(e, "error")
      this.$store.state.fastCarregando = false
    })
  },
  methods: {
    //#region métodos 1
    async getDadosMetodo1(){
      this.promiseGetFastApi("api/controller/lista", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        console.log(res)
      })
      .catch((e) => {
        this.exibeToasty(e, "error")
      });
    },
    async insertDadosMetodo1(){
      let obj = {}
      this.promisePostFastApi(obj, "api/controller/insere")
      .then((res) => {
        console.log(res)
      })
      .catch((e) => {
        this.exibeToasty(e, "error")
      });
    },
    async updateDadosMetodo1(){
      let obj = {}
      this.promisePostFastApi(obj, "api/controller/atualiza")
      .then((res) => {
        console.log(res)
      })
      .catch((e) => {
        this.exibeToasty(e, "error")
      });
    },
    async deleteDadosMetodo1(){
      let obj = {}
      this.promisePostFastApi(obj, "api/controller/exclui")
      .then((res) => {
        console.log(res)
      })
      .catch((e) => {
        this.exibeToasty(e, "error")
      });
    },
    //#endregion 
    //#region métodos 2
    
    //#endregion
  },
};
</script>

<style scope>

</style>
